<template>
  <div class="box">
    <div class="topBanner">
      <h2>安全、有效、专注</h2>
      <h3>北正细胞与您携手</h3>
      <h3>共同打造高品质的细胞应用服务</h3>
      <h6>Beizheng Sail joins hands with you Jointly create </h6>
      <h6>high-quality stem cell medical services</h6>
      <!-- <button class="btn">了解更多</button> -->
    </div>
    <div class="main-box">
      <div class="main-item">
        <img src="../assets/home/stem.png" alt="" />
        <h6>行业发展趋势的指引</h6>
        <p>干细胞治疗领域</p>
        <p>拥有独立的技术优势</p>
      </div>
      <div class="main-item">
        <img src="../assets/home/device.png" alt="" />
        <h6>先进设备和技术</h6>
        <p>实验室采用</p>
        <p>最先进设备和技术</p>
      </div>
      <div class="main-item">
        <img src="../assets/home/team.png" alt="" />
        <h6>高水准医疗技术保障</h6>
        <p>依托北大医疗产业园</p>
        <p>和相关专家支持</p>
      </div>
    </div>
    <div class="wwa-box">
      <h6>WHO WE ARE</h6>
      <h5>提供安全有效的</h5>
      <h5>干细胞技术与临床产品</h5>
      <p>北正赛欧 (北京)
        生物科技有限公司是一家以技术创新为导向的国家高新技术企业，专注于前沿生物科研与应用。公司成立于2014年，位于北京中关村生命科学园核心区，拥有国内外专家组成的专家科研团队。公司致力于为生命科学研究和再生医学应用技术的研发与产业化提供基础，并提供安全有效的干细胞技术及各种临床产品、衍生物的生物制品，以及提供与人体具有相融性的生物材料、修复再生的组织工程产品。
      </p>
      <h3>公司宗旨</h3>
      <h2>安全、有效、专注</h2>
      <div class="wwa-bj"></div>
      <div class="wwa-cp">
        <img src="../assets/home/gened.png" alt="">
        <h5>产品与服务</h5>
      </div>
    </div>
    <div class="main-pp">
      <h6>PREPARATION PROCESS</h6>
      <h5>制备工艺优势</h5>
      <h2>高标准干细胞实验室:先进设备、符合质控标准、精准服务保障</h2>
      <div class="process">
        <img src="../assets/home/stem.png" alt="">
        <h6>拥有独立的技术优势</h6>
        <p>
          公司拥有独立的技术优势，包括细胞制备技术超前、细胞产出效率高、无需配型等，使得公司制备的细胞纯度高、活性高、通用性好。
        </p>

      </div>
      <div class="process">
        <img src="../assets/home/patent.svg" alt="">
        <h6>多项发明专利技术</h6>
        <p>
          公司拥有多项发明专利技术,并解决了细胞制备方法中能获得高纯度、均一性好的细胞的行业难题。
        </p>

      </div>
      <div class="process">
        <img src="../assets/home/lab.png" alt="" />
        <h6>高标准的干细胞研发实验室</h6>
        <p>
          公司建有高标准的干细胞研发实验室，采用最先进的设备和技术，符合质控标准，保证细胞品质均一且产量高。
        </p>

      </div>
      <div class="process">
        <img src="../assets/home/team.png" alt="">
        <h6>资深的技术团队</h6>
        <p>
          公司拥有资深的技术团队，包括细胞生物学、基础医学和临床医学的博士、硕士，并依托北大医疗产业园及相关专家支持，在细胞治疗等领域具备较高的技术实力。
        </p>

      </div>
      <div class="process">
        <img src="../assets/home/thinking.png" alt="">
        <h6>致力于创新和新思路</h6>
        <p>
          公司致力于创新和新思路，在多方面专家支持的紧密协作下，不断研发出更好的细胞制备、储存、使用方法，以提高干细胞治疗的效果和安全性。
        </p>

      </div>
      <div class="process">
        <img src="../assets/home/secure.png" alt="">
        <h6>提供精准的服务保障</h6>
        <p>
          公司提供精准的服务保障，为研究更多拥有自主知识产权的高品质品种，对细胞应用提供精准的服务保障。
        </p>

      </div>
    </div>
    <div class="core">
      <h6>CORE TECHNICAL</h6>
      <h5>核心技术先进 制备的细胞优点多</h5>
      <h5>产量高 领先行业</h5>
      <h2>有多项核心技术，决定了公司制备的细胞纯度高、活性高，通用性好，使用时无需配型。并且细胞制备技术超前，细胞产出效率远远高出同类水平，在行业中脱颖而出。</h2>
      <img src="../assets/home/core.png" alt="" />
    </div>
    <div class="walk">
      <img @click="play" src="../assets/home/mvBtns.png" alt="" />
      <h6>WORKING VIDEO</h6>
      <h5>提供卓越的干细胞制备技术</h5>
      <h5>为临床治疗提供更加安全、有效的治疗方案</h5>
      <!-- <button class="btn">了解更多</button> -->
    </div>
    <div class="our">
      <h6>OUR SERVICE</h6>
      <h5>最新动态</h5>
      <div class="our-item">
        <img src="../assets/home/trends1.png" alt="">
        <p>干细胞创新药申报成功</p>
        <span>2022.08.30</span>
      </div>
      <div class="our-item">
        <img src="../assets/home/trends2.jpg" alt="">
        <p>与首都医科大学附属北京同仁医院合作进行临床前研究项目</p>
        <span>2023.05.29</span>
      </div>
      <div class="our-item">
        <img src="../assets/home/trends3.png" alt="">
        <p>我司代表赣州市参加“中国（南昌）国际大健康产业大会暨博览会”</p>
        <span>2023.06.29</span>
      </div>
    </div>
    <el-dialog title="北正视频" width="100%" :visible.sync="dialogVisible" :before-close="handleClose">
      <video ref="myVideo" class="bz_video" controls src="../assets/home/bz.mp4">
        <p>你的浏览器不支持video标签</p>
      </video>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      videoActive: false,
    }
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false;
      this.$refs.myVideo.pause();

    },
    play() {
      this.dialogVisible = true;
      if (this.videoActive) {
        this.$refs.myVideo.play();
      } else {
        this.videoActive = true;
      }

    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
}

.bz_video {
  width: 100%;
  height: 60%;
  object-fit: fill;
}


.btn {
  width: 18rem;
  height: 4.5rem;
  line-height: 4.5rem;
  font-size: 1.2rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
  outline: none;
  border: none;
  background: #23989f;
  border-radius: 1.55rem;
  margin: 0 auto;
  margin-top: 2.8rem;
  text-align: center;
  letter-spacing: 0.2rem;
}

.main-box {
  padding: 0 1rem;
  background-color: #fff;
  margin-bottom: 1rem;
}

.our {
  padding: 1rem;
  background-color: #fff;
  margin-bottom: 2rem;

  .our-item {
    width: 100%;
    margin-top: 3rem;

    img {
      width: 100%;
      height: 24rem;
    }

    p {
      font-size: 1.6rem;
      color: #0d5078;
      font-weight: bold;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    span {
      font-size: 1.2rem;
      color: #848484;
    }
  }

  h5 {
    font-size: 1.8rem;
    color: #115480;
    text-align: center;
    padding-top: 1rem;
  }

  h6 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
  }
}

.walk {
  width: 100%;
  height: 52rem;
  background: url(../assets/home/workings.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;

  img {
    width: 6rem;
    height: 6rem;
    margin-top: 18rem;
  }

  h5 {
    font-size: 1.8rem;
    color: #115480;
    text-align: center;
    padding-top: 1rem;
  }

  h6 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
  }

  .btn {
    margin-top: 5rem;
  }
}

.core {
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;

  h5 {
    font-size: 1.8rem;
    color: #115480;
    text-align: center;
    padding-top: 1rem;
  }

  h3 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 3rem;
  }

  h2 {
    color: #a3a8a4;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
    padding-bottom: 2rem;
    line-height: 2.4rem;
  }

  h6 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
  }

  img {
    width: 100%;
    height: 35rem;
  }
}

.main-pp {
  background-color: #fff;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;

  h5 {
    font-size: 1.8rem;
    color: #115480;
    text-align: center;
    padding-top: 1rem;
  }

  h3 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 3rem;
  }

  h2 {
    color: #a3a8a4;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
    padding-bottom: 2rem;
  }

  h6 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
  }
}

.process {
  width: 100%;
  min-height: 30rem;
  margin-top: 1.5rem;
  text-align: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;

  img {
    width: 4.5rem;
    height: 4.5rem;
    padding-top: 4.5rem;
  }

  p {
    font-size: 1.2rem;
    color: #a3a8a4;
    line-height: 2.4rem;
    width: 28rem;
    margin: 0 auto;
    padding-top: 1rem;
    font-weight: 500;
  }
}

.wwa-box {
  background-color: #eaefe9;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;

  h5 {
    font-size: 1.8rem;
    color: #115480;
    text-align: center;
    padding-top: 1rem;
  }

  h3 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 3rem;
  }

  h2 {
    color: #a3a8a4;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
    padding-bottom: 3rem;
  }

  h6 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 1.2rem;
  }

  p {
    font-size: 1.2rem;
    color: #a3a8a4;
    line-height: 2.4rem;

    padding-top: 2rem;
    text-indent: 2rem;
  }

  .wwa-bj {
    width: 100%;
    height: 29rem;
    background: url(../assets/home/genes.png);
  }

  .wwa-cp {
    width: 26rem;
    height: 20rem;
    margin: 0 auto;
    background-color: #fff;
    margin-top: -5rem;
    text-align: center;

    img {
      width: 6rem;
      height: 6rem;
      padding-top: 5rem;
    }

    h5 {
      font-size: 1.6rem;
      color: #054f73;
    }
  }
}

.main-item {
  width: 100%;
  height: 22rem;
  margin-top: 1rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;
  text-align: center;
}

.main-item>img {
  width: 4rem;
  height: 4rem;
  padding-top: 4.5rem;
}

.main-item>h6 {
  color: #32628a;
  font-size: 1.2rem;
  padding-top: 1.2rem;
}

.main-item>p {
  color: #848484;
  font-size: 1.2rem;
  padding-top: 1.2rem;
}

.topBanner {
  width: 100%;
  height: 55rem;
  background-color: #adcdda;
  background-image: url(../assets/home/banner.png);
  background-size: auto 37rem;
  background-repeat: no-repeat;
  background-position: top 0 right -3rem;
  text-align: center;
}

.topBanner>h2 {
  font-size: 2.4rem;
  font-weight: 600;
  color: #003867;
  text-align: center;
  padding-top: 38rem;
}

.topBanner>h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #0b5786;
  text-align: center;
  padding-top: 0.5rem;
}

.topBanner>h6 {
  color: #fff;
  font-size: 1rem;
  text-align: center;
  padding-top: 0.5rem;
}
// ::v-deep .el-dialog {
//   margin-top: 0 !important;
// }
::v-deep .el-dialog__body {
  padding: 0;
}

</style>
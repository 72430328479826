<template>
    <div class="box">
        <div class="topBanner"></div>
        <div class="head-card">
            <img src="../assets/finance/finance.png" alt="">
            <h3>解锁未来的财富之源</h3>
            <h6>选择细胞银行，开启金融投资新篇章</h6>
        </div>
        <!-- 中心内容 -->
        <div class="center-box">
            <div class="founder">
                <h6>INTRODUCTION TO CELL BANKING</h6>
                <h3>保值增值 为未来投资</h3>
            </div>
            <div class="bank">
                <img src="../assets/finance/finance (1).png" alt="">
                <div class="bank-main">
                    <img src="../assets/finance/financeIcon (2).png" alt="">
                    <h5>投资于未来的行业</h5>
                    <p>干细胞行业被认为是未来医学领域的重要发展方向，具有广阔的应用前景和商业潜力。作为细胞银行，我们致力于为投资者提供参与这个高成长行业的机会，让您的投资与未来的科学和医疗进步紧密相连。</p>
                </div>
            </div>
            <div class="bank">
                <img src="../assets/finance/finance (2).png" alt="">
                <div class="bank-main">
                    <img src="../assets/finance/financeIcon (1).png" alt="">
                    <h5>多样化的投资合作模式</h5>
                    <p>我们提供灵活多样的投资合作模式，包括股权投资、战略合作、项目合作等。无论您是个人投资者、机构投资者还是行业合作伙伴，我们都将与您合作，共同探索干细胞行业的投资机会和合作潜力。</p>
                </div>
            </div>
            <div class="bank">
                <img src="../assets/finance/finance (3).png" alt="">
                <div class="bank-main">
                    <img src="../assets/finance/financeIcon (3).png" alt="">
                    <h5>行业发展趋势的指引</h5>
                    <p>我们将为您提供关于干细胞行业发展趋势的专业指引和市场分析，助您作出明智的投资决策。我们的团队将时刻关注行业动态，为您提供最新的行业信息和前沿趋势，以帮助您把握投资机遇。</p>
                </div>
            </div>
        </div>
        <div class="info">
            <div class="form">
                <el-input class="form-item" v-model="name" style="margin-top: 3rem;" placeholder="姓名"></el-input>
                <el-input class="form-item" v-model="phone" placeholder="手机号"></el-input>
                <el-input type="textarea" v-model="text" class="form-textarea" placeholder="信息"></el-input>
                <el-button class="form-item" style="color: #72bcbf;">提交</el-button>
            </div>
        </div>
        <div class="fin">
            <h2>提供专业的咨询服务</h2>
            <h3>为您提供个性化的存储方案和建议</h3>
            <button class="btn" @click="handleTo">联系我们</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: '',
            phone: '',
            text: '',
        }
    },
    methods: {
        handleTo() {
            this.$router.push({ path: '/aboutUs', query: { id: 'contUs' } })
        }

    }
}
</script>

<style lang="less" scoped>
.topBanner {
    width: 100%;
    height: 28rem;
    background-image: url("../assets/finance/finance-banner.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
}

.btn {
    width: 18rem;
    height: 4.5rem;
    line-height: 4.5rem;
    font-size: 1.2rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #23989f;
    outline: none;
    border: none;
    background: #fff;
    border-radius: 1rem;
    margin: 0 auto;
    margin-top: 3.8rem;
    text-align: center;
    letter-spacing: 0.2rem;
}

.head-card {
    height: 26rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: -6rem;
    background-color: #23989f;

    img {
        width: 100%;
        height: 11.5rem;
    }

    h3 {
        padding-top: 3rem;
        font-size: 2.2rem;
        color: #fff;
        text-align: center;
    }

    h6 {
        color: #fff;
        font-size: 1.2rem;
        margin-top: 1rem;
        line-height: 2rem;
        padding: 0 3.5rem;
        text-align: center;
    }
}

.info {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 50rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background: url(../assets/finance/formImg.png) no-repeat;
    background-size: 100% 100%;
    overflow: hidden;

    .form {
        width: 100%;
        height: 37rem;
        background: #23989f;
        margin-top: 5rem;
        text-align: center;

        /deep/ .el-input__inner::placeholder {
            color: #fff !important;
        }

        /deep/ .el-textarea__inner::placeholder {
            color: #fff !important;
        }

        /deep/ .el-input__inner {
            outline: none;
            border: none;
            color: #fff !important;
            background-color: #72bcbf !important;

        }
    }

    .form-item {
        width: 30rem;
        height: 4rem;
        outline: none;
        border: none;
        margin: 0 auto;
        margin-top: 1.5rem;
    }

    .form-textarea {
        width: 30rem;
        height: 15rem;
        margin-top: 1.5rem;
    }

    .form-textarea /deep/ .el-textarea__inner {
        height: 15rem !important;
        background-color: #72bcbf !important;
        color: #fff !important;
        outline: none;
        border: none;
    }

}

.fin {
    width: 100%;
    height: 47rem;
    background: url(../assets/finance/fin-bg.png) no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;

    h2 {
        font-size: 1.8rem;
        color: #003462;
        text-align: center;
        margin-top: 20rem;
    }

    h3 {
        font-size: 1.8rem;
        color: #003462;
        text-align: center;
        margin-top: 1rem;
    }
}

.bank {
    width: 100%;
    height: 50rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 0 5px 2px #eaeaf8;

    img {
        width: 100%;
        height: 28rem;
    }

    .bank-main {
        width: 100%;

        // text-align: center;
        img {
            width: 4rem;
            height: 4rem;
            display: block;
            margin: 0 auto;
            margin-top: 2rem;
        }

        h5 {
            color: #115480;
            font-size: 1.4rem;
            text-align: center;
            padding-top: 1rem;
        }

        p {
            color: #737373;
            font-size: 1.2rem;
            line-height: 2.4rem;
            padding-left: 2rem;
            padding-right: 2rem;
            margin-top: 1rem;
        }
    }
}

// 中心内容
.center-box {
    padding: 0rem 1rem;
}

.founder {
    padding-bottom: 2rem;

    h6 {
        color: #115480;
        font-size: 1.2rem;
        text-align: center;
        padding-top: 3rem;
    }

    h3 {
        font-size: 1.8rem;
        color: #115480;
        text-align: center;
        padding-top: 1rem;
    }

    p {
        color: #737373;
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    img {
        width: 100%;
        height: 24rem;
        padding-top: 2rem;
    }

    .qm {
        height: 4rem;
        display: flex;
        justify-content: right;
        align-items: center;

        // margin-bottom: 2rem;
        span {
            font-size: 1.2rem;
            color: #486995;
        }

        img {
            width: 3rem;
            height: 1.6rem;
            padding-top: 0;
        }
    }

    .company {
        width: 28rem;
        height: 32rem;
        margin: 2rem auto;
        display: block;
    }

    .ry {
        display: flex;
        justify-content: space-between;

        img {
            width: 8rem;
            height: 5.5rem;
        }
    }

    .ry1 {
        display: flex;
        justify-content: space-between;

        img {
            width: 8rem;
            height: 12rem;
        }
    }


}
</style>
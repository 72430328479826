<template>
    <div class="box">
        <div class="topBanner">
            <div class="head-card">
                <h3>呵护身心 健康同行</h3>
                <h6>我们的专业团队将为您提供个性化的健康管理方案，助您享受健康人生！</h6>
            </div>
        </div>
        <!-- 中心内容 -->
        <div class="center-box">
            <div class="founder">
                <h6>CHERISH YOUR HEALTH TAKE CARE OF US</h6>
                <h3>珍爱健康 由我们呵护</h3>
            </div>
            <div class="tp">
                <img src="../assets/health/cherish (6).png" alt="">
            </div>
            <div class="tp">
                <img src="../assets/health/cherish (5).png" alt="">
            </div>
            <div class="tp">
                <img src="../assets/health/cherish (4).png" alt="">
            </div>
            <div class="tp">
                <img src="../assets/health/cherish (3).png" alt="">
            </div>
            <div class="tp">
                <img src="../assets/health/cherish (2).png" alt="">
            </div>
            <div class="tp">
                <img src="../assets/health/cherish (1).png" alt="">
            </div>
            <div class="founder">
                <h6>NTRODUCTION TO HEALTH MANAGEMENT</h6>
                <h3>全力帮助您管理和提升健康</h3>
                <h3>实现全面的身心健康</h3>
            </div>
            <div class="jk">
                <img src="../assets/health/health1.png" alt="">
                <h6>个性化健康评估</h6>
                <p>通过详细的个人健康评估，包括体检结果、疾病风险评估和家族病史等信息，为每位客户提供量身定制的健康管理计划。</p>
            </div>
            <div class="jk">
                <img src="../assets/health/health2.png" alt="">
                <h6>健康检测和监测</h6>
                <p>利用先进的医疗设备和技术，提供全面的健康检测服务，包括血液检测、影像学检查和遗传检测等，以及定期的健康监测，确保您的身体状况得到及时监护。</p>
            </div>
            <div class="jk">
                <img src="../assets/health/health3.png" alt="">
                <h6>营养咨询和饮食指导</h6>
                <p>与专业的营养师合作，为您提供个性化的营养咨询和饮食指导，制定适合您的饮食计划，帮助您实现健康的饮食习惯和营养平衡。</p>
            </div>
            <div class="jk">
                <img src="../assets/health/health4.png" alt="">
                <h6>运动指导和健身计划</h6>
                <p>与专业的运动教练合作，为您制定个性化的运动指导和健身计划，根据您的需求和目标，定制适合您的运动方案，提升身体健康和体能水平。</p>
            </div>
            <div class="jk">
                <img src="../assets/health/health5.png" alt="">
                <h6>心理健康支持</h6>
                <p>我们提供心理健康支持，与专业心理咨询师合作，为您提供心理咨询和支持，帮助您应对压力、焦虑和情绪问题，维护心理健康和幸福感。</p>
            </div>
            <div class="jk">
                <img src="../assets/health/health6.png" alt="">
                <h6>健康教育和培训</h6>
                <p>通过健康教育和培训活动，我们致力于提升客户的健康意识和健康知识，为您提供有关健康生活方式、疾病预防和健康管理的知识，使您能够做出更明智的健康决策。</p>
            </div>
            <div class="cp">
                <img src="../assets/health/product.jpg" alt="">
            </div>
            <div class="cp">
                <img src="../assets/health/mlx.jpg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>

<style lang="less" scoped>
.topBanner {
    width: 100%;
    height: 28rem;
    background-image: url("../assets/health/health-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .head-card {
        width: 100%;
        height: 11rem;
        margin-top: 8rem;
        text-align: center;
        background-image: url(../assets/storage/banner-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;

        h3 {
            padding-top: 1.7rem;
            font-size: 2.2rem;
            color: #fff;
        }

        h6 {
            color: #fff;
            font-size: 1.2rem;
            margin-top: 1rem;
            line-height: 2rem;
            padding: 0 3.5rem;
            text-align: left;
            text-indent: 2em;
        }
    }
}

.tp {
    width: 100%;
    height: 27rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    img {
        width: 100%;
        height: 100%;
    }
}
.cp{
    width: 100%;
    height: 17rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    img {
        width: 100%;
        height: 100%;
    }
}

.jk {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;

    img {
        width: 6rem;
        height: 6rem;
    }

    h6 {
        color: #115480;
        font-size: 1.4rem;
        text-align: center;
        padding-top: 3rem;
    }

    p {
        color: #737373;
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
        margin-left: 4rem;
        margin-right: 4rem;
    }
}

// 中心内容
.center-box {
    padding: 0rem 1rem;
}

.founder {
    padding-bottom: 2rem;

    h6 {
        color: #115480;
        font-size: 1.2rem;
        text-align: center;
        padding-top: 3rem;
    }

    h3 {
        font-size: 1.8rem;
        color: #115480;
        text-align: center;
        padding-top: 1rem;
    }

    p {
        color: #737373;
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    img {
        width: 100%;
        height: 24rem;
        padding-top: 2rem;
    }

    .qm {
        height: 4rem;
        display: flex;
        justify-content: right;
        align-items: center;

        // margin-bottom: 2rem;
        span {
            font-size: 1.2rem;
            color: #486995;
        }

        img {
            width: 3rem;
            height: 1.6rem;
            padding-top: 0;
        }
    }

    .company {
        width: 28rem;
        height: 32rem;
        margin: 2rem auto;
        display: block;
    }

    .ry {
        display: flex;
        justify-content: space-between;

        img {
            width: 8rem;
            height: 5.5rem;
        }
    }

    .ry1 {
        display: flex;
        justify-content: space-between;

        img {
            width: 8rem;
            height: 12rem;
        }
    }


}
</style>
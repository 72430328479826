<template>
  <div class="box">
    <!-- 头部部分 -->
    <div class="title">
      <div class="left">
        <img src="../assets/home/bz-logo.png" @click="toHome()" alt="" />
      </div>
      <div class="right" @click="menuClick">
        <img :src="menuImgSrc[menuShow ? 1 : 0]" alt="" />
      </div>
    </div>
    <!-- 中心内容出口 -->
    <div style="margin-top: 5.2rem; background: #f4f8ff">
      <router-view />
    </div>
    <!-- 脚部 -->
    <div class="foot-box">
      <div class="foot">
        <img class="footLogo" src="../assets/foot/footer-logo.png" alt="">
        <p>北正赛欧(北京)生物科技有限公司是一家在生命健康领域以技术创新为导向，以模式创新为核心致力于前沿生物科研与应用的国家高新技术企业。</p>
        <!-- <div class="foot-titleText">专业服务</div>
        <p>细胞创新药研发</p>
        <p>细胞技术研究</p>
        <p>细胞存储服务</p>
        <p>抗衰及医美产品开发</p>
        <div class="foot-titleText">专业服务</div>
        <p>关于我们</p>
        <p>专业优势</p>
        <p>专利及荣誉</p>
        <p>专家团队</p> -->
        <div class="foot-titleText">联系我们</div>
        <p style="margin-bottom: 1rem;">如果您对我们的技术、产品或服务感兴趣，请随时联系我们。</p>
        <p><img src="../assets/foot/phone.png" alt="">8610-80765233</p>
        <p><img src="../assets/foot/email.png" alt="">beizhengsc@bz-sc.com</p>
        <p><img src="../assets/foot/address.png" alt="">北京市昌平区生命园中路8号一区10号楼4层</p>
        <!-- <div class="foot-info">
          <div class="foot-adress">
            <div class="address">地址：</div>
            <span>北京市昌平区生命园中路 8 号一区 10 号楼 4层</span>
          </div>
          <div class="foot-phone">电话：<span>8610-80765233</span></div>
          <div class="foot-email">邮箱：<span>beizhengsc@bz-sc.com</span></div>
        </div> -->
      </div>
      
    </div>
    <div class="foots">
      <h2>版权所有 @ 北正赛欧(北京)生物科技有限公司</h2>
      <h2>京ICP备2022014291号-1</h2>
    </div>
    <!-- 导航弹框 -->
    <div class="fixed-nav" v-show="menuShow">
      <div class="bj" @click="navClose"></div>
      <div class="nav-content">
        <router-link to="/home">
          <div :class="$route.name == 'home' ? 'nav-item active' : 'nav-item'" @click="navClose">首页</div>
        </router-link>
        <router-link to="/storage">
          <div :class="$route.name == 'storage' ? 'nav-item active' : 'nav-item'"" @click="navClose">干细胞存储</div>
        </router-link>
        <router-link to="/application">
          <div :class="$route.name == 'application' ? 'nav-item active' : 'nav-item'" @click="navClose">细胞应用</div>
        </router-link>
        <router-link to="/aboutUs">
          <div :class="$route.name == 'aboutUs' ? 'nav-item active' : 'nav-item'" @click="navClose">关于我们</div>
        </router-link>
        <router-link to="/resource">
          <div :class="$route.name == 'resource' ? 'nav-item active' : 'nav-item'" @click="navClose">北正资源</div>
        </router-link>
        <router-link to="/health">
          <div :class="$route.name == 'health' ? 'nav-item active' : 'nav-item'" @click="navClose">健康管理</div>
        </router-link>
        <router-link to="/finance">
          <div :class="$route.name == 'finance' ? 'nav-item active' : 'nav-item'" @click="navClose">财富投资</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuShow: false,
      cateList: [],
      centerConfig: {},
      status: 'home',
      menuImgSrc: [require('../assets/home/nav-icon.png'), require('../assets/home/close.png')],
    }
  },
  methods: {
    toHome() {
      this.$router.push('/home')
    },
    // 数据请求
    menuClick() {
      this.menuShow = !this.menuShow
    },
    navClose() {
      this.menuShow = false
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  background-color: #eee;
  overflow: hidden;
}
.foots{
  width: 100%;
  height: 8rem;
  border-top: 2px solid #4d8799;
  background: #0e5e79;
  h2{
    font-size: 1.2rem;
    color: #fff;
    line-height: 2.4rem;
    text-align: center;
    margin-top: 1rem;
    font-weight: 400;
  }
}

.foot {
  p {
    color: #fff;
    font-size: 1.2rem;
    line-height: 2.2rem;
    margin-top: 1rem;
    display: flex;
    img{
      width: 2rem;
      height: 2rem;
      padding-right: 0.5rem;
    }
  }
}

.footLogo {
  width: 10rem;
  height: 3rem;
  margin-top: 1rem;
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}

a {
  text-decoration: none;
}

.active-text {
  color: blue;
}

.box {
  background: #ffffff;
}

.title {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 5.2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  background: #fff;
}

.title>.left {
  width: 7.1rem;
  line-height: 3.8rem;
  margin-left: 1.2rem;
}

.left>img {
  width: 6.5rem;
  padding: 1.3rem 0rem;
}

.right>img {
  padding-top: 2rem;
  width: 1.7rem;
  margin-right: 1.2rem;
}

// 轮播部分
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 1rem;
  line-height: 7.5rem;
  text-align: center;
  background-color: #39a9ed;
}

.foot-box {
  padding: 1.2rem 0 1.2rem 1.2rem;
  background: #0e5e79;
}

.foot-titleText {
  padding-top: 1.6rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.foot-info {
  padding-top: 1rem;
}

.foot-adress {
  display: flex;
}

.foot-adress,
.foot-phone,
.foot-email {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-bottom: 0.6rem;
}

.foot-code {
  padding-top: 1.3rem;
  padding-bottom: 2.8rem;
  display: flex;
  justify-content: space-around;
}

.foot-code-img>img {
  width: 5rem;
  height: 5rem;
}

.foot-code-text {
  font-size: 1.2rem;
  height: 0.7rem;
  line-height: 0.7rem;
  padding-top: 1.2rem;
  color: #3b3d41;
  text-align: center;
}

.foot-code-text1 {
  margin-left: 0.5rem;
}

.fixed-nav {
  position: fixed;
  left: 0;
  top: 5.2rem;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}

.fixed-nav .bj {
  background: rgba(59, 61, 65, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}

.fixed-nav .nav-content {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 102;
  background: #23989f;
  width: 100%;
  // height: 100%;
  padding-top: 2rem;
  padding-bottom: 3rem;
}

.nav-content::-webkit-scrollbar {
  display: none;
}

.fixed-nav .nav-content .nav-item {
  line-height: 5rem;
  width: 30rem;
  padding-left: 3rem;
  margin: 0 auto;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  border-top: 1px solid #5ea7ad;
  border-bottom: 1px solid #5ea7ad;
}

.fixed-nav .nav-content .active {
  border-left: 5px solid #fefefc;
}

.address {
  white-space: nowrap;
  // width: 100%;
}

/deep/.el-submenu__title {
  font-size: 1.6rem;
  line-height: 3rem;
  height: 5rem;
  // line-height: 2.6rem;
  padding: 0rem 0rem;
  padding-left: 0rem !important;
}

/deep/.el-menu {
  width: 112%;
}

/deep/.el-menu-item {
  font-size: 1.6rem;
  padding: 0rem 0rem;
  padding-left: 0rem !important;
}

/deep/.el-icon-arrow-down:before {
  font-size: 1rem;
}

/deep/.el-submenu .el-menu-item {
  padding: 0 2.25rem;
  min-width: 10rem;
  height: 4rem;
  line-height: 4rem;
}

/deep/ .el-icon-arrow-down:before {
  font-size: 2rem;
}

/deep/.el-submenu__icon-arrow {
  top: 25%;
}

/deep/.el-menu-item-group>ul {
  padding: 0rem 1.45rem;
}

/deep/.el-menu-item-group__title {
  padding: 0rem;
}

/deep/.el-menu-item.is-active {
  background-color: white !important;
  color: #4676f8;
}

/deep/.el-submenu__title:hover {
  background-color: white;
}
</style>

import http from './public'


// 用户端

// 获取轮播图列表
export const GetBannerList = (params) => {
    return http.fetchPost('/api/carousel_img/listCarouselImg', params)
}
// 获取文章分类列表
export const GetArticleCategoryList = (params) => {
    return http.fetchPost('/api/article_category/listArticleCategory', params)
}
// 获取文章列表
export const GetArticleList = (params) => {
    return http.fetchPost('/api/article/listArticle', params)
}
// 获取文章详情
export const GetArticleDetail = (params) => {
    return http.fetchPost('/api/article/getArticle', params)
}
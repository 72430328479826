<template>
    <div class="box">
        <div class="topBanner">
            <div class="head-card">
                <h3>汇聚力量 共铸辉煌</h3>
                <h6>与国内外优秀伙伴合作，共享干细胞研究的丰硕成果，开创行业的新纪元!</h6>
            </div>
        </div>
        <!-- 中心内容 -->
        <div class="center-box">
            <div class="founder">
                <h6>FOUNDER INTRODUCTION</h6>
                <h3>京赣产业联动</h3>
                <p>依托北京公司优质资源，支持革命苏区生物产业。北正旗下江西人体组织样本库是江西省第一家专业干细胞制备中心及人体组织样本存储库。</p>
                <p>公司位于江西省赣州市经济技术开发区国际企业中心。以“关注生命提高生命质量”为宗旨。坚持“诚信、专业、先进、发展”的企业文化。掌握美国、中国多项专利技术，拥有20 项国家保护专利。</p>
                <p>
                    江西人体组织样本库以“组织细胞库+区域细胞制备中心+细胞质量云平台”(1库 +1
                    中心+1平台)产业科研发展新模式。推动江西省人体组织样本资源的收集及应用，为革命苏区生物产业发展和人民的生命健康做出应有贡献。
                </p>
                <p>北正以江西公司作为未来上市主体，江西公司依托北京总部相关资源，在当地建立细胞全产业链的高标准平台。</p>
            </div>
            <div class="team">
                <img src="../assets/resource/yyx.png" alt="">
                <div class="presend">
                    <div>
                        <h3>尹玉新</h3>
                        <div class="lable">
                            <i class="el-icon-right iconRight"></i><span>首席科学家</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team">
                <img src="../assets/resource/xyz.png" alt="">
                <div class="presend">
                    <div>
                        <h3>奚永志</h3>
                        <div class="lable">
                            <i class="el-icon-right iconRight"></i><span>临床医学的博士</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team">
                <img src="../assets/resource/gsp.png" alt="">
                <div class="presend">
                    <div>
                        <h3>高舒平</h3>
                        <div class="lable">
                            <i class="el-icon-right iconRight"></i><span>高级顾问</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team">
                <img src="../assets/resource/mjp.jpg" alt="">
                <div class="presend">
                    <div>
                        <h3>毛建平</h3>
                        <div class="lable">
                            <i class="el-icon-right iconRight"></i><span>研发负责人</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team">
                <img src="../assets/resource/wcs.jpg" alt="">
                <div class="presend">
                    <div>
                        <h3>王长生</h3>
                        <div class="lable">
                            <i class="el-icon-right iconRight"></i><span>北京首席专家</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="team">
                <img src="../assets/resource/xhb.jpg" alt="">
                <div class="presend">
                    <div>
                        <h3>辛洪波</h3>
                        <div class="lable">
                            <i class="el-icon-right iconRight"></i><span>南昌大学医学院院长</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="nteam">
                <img src="../assets/resource/dr.png" alt="" />
                <div>
                    <h2>200+</h2>
                    <span>博士团队</span>
                </div>
            </div>
            <div class="nteam">
                <img src="../assets/resource/postgraduate.png" alt="" />
                <div>
                    <h2>500+</h2>
                    <span>研究生</span>
                </div>

            </div>
            <div class="nteam">
                <img src="../assets/resource/researchers.png" alt="" />
                <div>
                    <h2>500+</h2>
                    <span>研究专家</span>
                </div>

            </div>
            <div class="nteam">
                <img src="../assets/resource/Clinical.png" alt="" />
                <div>
                    <h2>500+</h2>
                    <span>临床专家</span>
                </div>
            </div>
            <div class="founder" style="margin-top: 6rem;">
                <h6>QUALIFICATION</h6>
                <h3>荣誉资质</h3>
                <div class="ry">
                    <img src="../assets/aboutUs/group2.jpg" alt="">
                    <img src="../assets/aboutUs/group1.jpg" alt="">
                    <img src="../assets/aboutUs/group3.jpg" alt="">
                    <img src="../assets/aboutUs/group4.jpg" alt="">
                </div>
                <div class="ry1">
                    <img src="../assets/aboutUs/group1.png" alt="">
                    <img src="../assets/aboutUs/group2.png" alt="">
                    <img src="../assets/aboutUs/group3.png" alt="">
                    <img src="../assets/aboutUs/group5.jpg" alt="">
                </div>
            </div>
            <div class="founder">
                <h6>GROUP QUALIFICATION</h6>
                <h3>专利证书</h3>
                <div class="zl">
                    <img src="../assets/resource/zl (1).jpg" alt="">
                    <img src="../assets/resource/zl (2).jpg" alt="">
                    <img src="../assets/resource/zl (3).jpg" alt="">
                    <img src="../assets/resource/zl (4).jpg" alt="">
                    <img src="../assets/resource/zl (5).jpg" alt="">
                </div>
            </div>
            <div class="founder">
                <h6>GMP LABORATORY</h6>
                <h3>GMP实验室</h3>
                <el-carousel :interval="3000" type="card" height="13rem">
                    <el-carousel-item v-for="(item, index) in imgList" :key="index">
                        <img :src="imgURL + item.img_url" class="gmp_img" alt="" /></el-carousel-item>
                </el-carousel>
            </div>
            <div class="service">
                <img src="../assets/resource/partner2.png" alt="">
                <div class="sve">
                    <img src="../assets/resource/hospital.png" alt="" />
                    <div>
                        <h2>Partner</h2>
                        <span>北京天坛医院</span>
                    </div>
                </div>
            </div>
            <div class="service">
                <img src="../assets/resource/partner3.png" alt="">
                <div class="sve">
                    <img src="../assets/resource/hospital.png" alt="" />
                    <div>
                        <h2>Partner</h2>
                        <span>北京地坛医院</span>
                    </div>
                </div>
            </div>
            <div class="service">
                <img src="../assets/resource/partner1.png" alt="">
                <div class="sve">
                    <img src="../assets/resource/hospital.png" alt="" />
                    <div>
                        <h2>Partner</h2>
                        <span>中日友好医院</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetBannerList } from "@/api/index";
export default {

    data() {
        return {
            imgList: [],
            imgURL: 'https://www-api.beizhengc.com'
        }

    },
    created() {
        this.getBanner();
    },

    methods: {
        getBanner() {
            let params = {
                location: 2,
            };
            GetBannerList(params).then((res) => {
                this.imgList = res.data.data.data;
            });
        },

    }
}
</script>

<style lang="less" scoped>
.topBanner {
    width: 100%;
    height: 28rem;
    background-image: url("../assets/resource/resource-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .head-card {
        width: 100%;
        height: 11rem;
        margin-top: 8rem;
        text-align: center;
        background-image: url(../assets/storage/banner-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;

        h3 {
            padding-top: 1.7rem;
            font-size: 2.2rem;
            color: #fff;
        }

        h6 {
            color: #fff;
            font-size: 1.2rem;
            margin-top: 1rem;
            line-height: 2rem;
            padding: 0 3.5rem;
            text-align: left;
            text-indent: 2em;
        }
    }
}

.service {
    width: 100%;
    height: 40rem;
    margin-bottom: 3rem;

    img {
        width: 100%;
        height: 28rem;
    }

    .sve {
        width: 100%;
        height: 12rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 5px 2px #eaeaf8;

        img {
            width: 5rem;
            height: 4rem;
        }

        h2 {
            font-size: 1.2rem;
            font-weight: bolder;
            color: #005a7e;
            padding-left: 1rem;
            margin-bottom: 1rem;
        }

        span {
            font-size: 1.4rem;
            color: #686a69;
            font-weight: bold;
            padding-left: 1rem;
        }
    }
}

.zl {
    width: 100%;

    img {
        margin: 2.5rem auto;
        width: 15rem !important;
        height: 21rem;
        display: block;
    }
}

.nteam {
    width: 100%;
    height: 12rem;
    margin-top: 6rem;
    border-radius: 0.6rem;
    border: 1px solid #d5d3d2;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 5rem;
        height: 5rem;
    }

    h2 {
        font-size: 1.8rem;
        font-weight: bolder;
        color: #005a7e;
        padding-left: 1rem;
        margin-bottom: 1rem;
    }

    span {
        font-size: 1.6rem;
        color: #747474;
        padding-left: 1rem;
        margin: 0;
    }

}

.team {
    height: 41rem;
    border-radius: 0.5rem;
    position: relative;
    margin-top: 6rem;

    img {
        width: 100%;
        height: 100%;
    }

    .presend {
        width: 25rem;
        height: 4rem;
        background-color: #fff;
        position: absolute;
        bottom: -4.5rem;
        left: 2rem;
        border-radius: 0.5rem;
        padding: 2.4rem 3rem;
        display: flex;
        justify-content: space-between;
        border: 1px solid #ccc;
    }

    .presend h3 {
        font-size: 1.8rem;
        color: #005a7e;
        font-weight: bold;
        margin: 0;
        margin-bottom: 1rem;
    }

    .presend .iconRight {
        color: #5f80c8;
        font-size: 1.4rem;
        font-weight: 600;
    }

    .presend .lable {
        display: flex;
        align-items: center;
    }

    .presend span {
        font-size: 1.4rem;
        color: #747474;
        font-weight: 600;
        padding-left: 0.6rem;
    }

}

// 中心内容
.center-box {
    padding: 0rem 1rem;
}

.founder {
    padding-bottom: 2rem;

    h6 {
        color: #115480;
        font-size: 1.2rem;
        text-align: center;
        padding-top: 3rem;
    }

    h3 {
        font-size: 1.8rem;
        color: #115480;
        text-align: center;
        padding-top: 1rem;
    }

    p {
        color: #737373;
        font-size: 1.2rem;
        line-height: 2.4rem;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    img {
        width: 100%;
        height: 24rem;
        padding-top: 2rem;
    }

    .qm {
        height: 4rem;
        display: flex;
        justify-content: right;
        align-items: center;

        // margin-bottom: 2rem;
        span {
            font-size: 1.2rem;
            color: #486995;
        }

        img {
            width: 3rem;
            height: 1.6rem;
            padding-top: 0;
        }
    }

    .company {
        width: 28rem;
        height: 32rem;
        margin: 2rem auto;
        display: block;
    }

    .ry {
        display: flex;
        justify-content: space-between;

        img {
            width: 8rem;
            height: 5.5rem;
        }
    }

    .ry1 {
        display: flex;
        justify-content: space-between;

        img {
            width: 8rem;
            height: 12rem;
        }
    }


}
</style>
<template>
  <div class="box">
    <div class="topBanner">
      <div class="head-card">
        <h3>创新引领 医疗先行</h3>
        <h6>我们是一家以技术创新为核心的生物科技企业，致力于为生命科学研究和医学应用提供领先的解决方案!</h6>
      </div>
    </div>
    <!-- 中心内容 -->
    <div class="center-box">
      <div class="founder">
        <h6>FOUNDER INTRODUCTION</h6>
        <h3>走进北正</h3>
        <p>
          北正生物起源于2008年，总部位于北京中关村生命科技园核心区-北大医疗产业园。公司汇集了北大基础医学院、北大生命科学学院、北大干细胞研究中心和军事科学院等相关机构技术和专家团队，专注于干细胞、免疫细胞技术创新。公司已取得国内外多项干细胞,免疫细胞技术发明专利。
        </p>
        <p>公司现有北京总部基地和赣南区域细胞制备中心两个分中心,分别负责细胞技术和创新药物研发、干细胞种子资源保藏工作,建立了涵盖细胞产业上中下游的全产业布局。</p>
        <p>
          目前，公司正积极摊进细胞创新药物研究与临床申报工作,结合细胞产业发展趋势和细胞药物生产特点,是全球第一家全面采用3D细胞培养工艺流程的细胞药物创新企业,并以此为基础进行干细胞药物研发创新及药物申报。通过3D自动化生物反应器和细胞收获系统的应用,全面替代传统2D干细胞培养模式，建立了3D干细胞培养工艺、质量检验、分级建库、药学研究等全套标准,实现干细胞生产的定制化、智能化、自动化和规模化。
        </p>
        <img src="../assets/aboutUs/bzaddress.png" alt="">
      </div>
      <div class="founder">
        <h6>FOUNDER INTRODUCTIONN</h6>
        <h3>创始人介绍</h3>
        <p>20多年从事康复医学临床及医疗管理工作经验，擅长进行医疗机构整体运营及管理，在京沪赣等地区有丰富的人脉资源。</p>
        <p>早在2003一2004年干细胞研究进入我国的初期，就已开始涉足这一领域至今约20年。并在2008年北京奥运会及残奥会，获颁“医疗卫生保障突出贡献荣誉证书”。</p>
        <p>北正作为一家致力于前沿生物科研与应用的企业，我们的使命是为人类的健康和幸福作出贡献。</p>
        <img src="../assets/aboutUs/zhaoyong.png" alt="">
        <div class="qm"><span>北正创始人：</span> <img src="../assets/aboutUs/zy.png" alt=""></div>
      </div>
      <div class="founder">
        <h6>FOUNDER INTRODUCTION</h6>
        <h3>京赣产业联动</h3>
        <p>依托北京公司优质资源，支持革命苏区生物产业。北正旗下江西人体组织样本库是江西省第一家专业干细胞制备中心及人体组织样本存储库。</p>
        <p>公司位于江西省赣州市经济技术开发区国际企业中心。以“关注生命,提高生命质量”为宗旨。坚持“诚信、专业、先进、发展”的企业文化。掌握美国、中国多项专利技术,拥有20项国家保护专利。</p>
        <p>江西人体组织样本库以“组织细胞库+区域细胞制备中心+细胞质量云平台”(1库+1中心+1平台)产业科研发展新模式。推动江西省人体组织样本资源的收集及应用,为革命苏区生物产业发展和人民的生命健康做出应有贡献。</p>
        <p>北正以江西公司作为未来上市主体,江西公司依托北京总部相关资源,在当地建立细胞全产业链的高标准平台。</p>
        <h6>江西人体组织样本库(全国第一家组织样本库)</h6>
        <h6>赣南区域细胞制备中心(卫健委官方)</h6>
        <h6>赣州细胞质量检验中心(质量中心)</h6>
        <h6>赣州细胞与组织工程中心(技术研发中心)</h6>
        <img class="company" src="../assets/aboutUs/company.jpg" alt="">
      </div>
      <div class="founder">
        <h6>QUALIFICATION</h6>
        <h3>荣誉资质</h3>
        <div class="ry">
          <img src="../assets/aboutUs/group2.jpg" alt="">
          <img src="../assets/aboutUs/group1.jpg" alt="">
          <img src="../assets/aboutUs/group3.jpg" alt="">
          <img src="../assets/aboutUs/group4.jpg" alt="">
        </div>
        <div class="ry1">
          <img src="../assets/aboutUs/group1.png" alt="">
          <img src="../assets/aboutUs/group2.png" alt="">
          <img src="../assets/aboutUs/group3.png" alt="">
          <img src="../assets/aboutUs/group5.jpg" alt="">
        </div>
      </div>
      <div class="founder">
        <h6>CONTACT US</h6>
        <h3>联系我们</h3>
      </div>
    </div>
    <div class="us"></div>
    <div id="contUs"></div>
    <div class="address">
      <h2>北正赛欧(北京)生物科技有限公司</h2>
      <div class="ad">
        <img src="../assets/aboutUs/phones.png" alt="">
        <p>8610-80765233</p>
      </div>
      <div class="ad">
        <img src="../assets/aboutUs/addresss.png" alt="">
        <p>北京市昌平区生命园中路8号一区10号楼4层</p>
      </div>
      <div class="ad">
        <img src="../assets/aboutUs/emails.png" alt="">
        <p>beizhengsc@bz-sc.com</p>
      </div>
      <div class="code">
        <img src="../assets/aboutUs/bjgzh.png" alt="">
        <p>北正干细胞</p>
      </div>

    </div>
    <div class="address">
      <h2>江西北正干细胞生物科技有限公司</h2>
      <div class="ad">
        <img src="../assets/aboutUs/phones.png" alt="">
        <p>0797-8370337</p>
      </div>

      <div class="ad">
        <img src="../assets/aboutUs/addresss.png" alt="">
        <p>江西省赣州市赣州经济技术开发区香江大道北侧、华坚北路西侧赣州国际企业中心5A号楼101室</p>
      </div>
      <div class="ad">
        <img src="../assets/aboutUs/emails.png" alt="">
        <p>beizhengsc@bz-sc.com</p>
      </div>
      <div class="code">
        <img src="../assets/aboutUs/jxgzh.png" alt="">
        <p>赣南区域细胞制备中心</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  mounted() {
    var usid = this.$route.query.id;
    if (usid) {
      console.log(usid)
      var anchor = document.getElementById(usid);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      // window.pageYOffset = anchor.offsetTop;
    }

  }
}
</script>

<style lang="less" scoped>
.topBanner {
  width: 100%;
  height: 28rem;
  background-image: url("../assets/aboutUs/aboutUsBanner.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  .head-card {
    width: 100%;
    height: 11rem;
    margin-top: 8rem;
    text-align: center;
    background-image: url(../assets/storage/banner-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;

    h3 {
      padding-top: 1.7rem;
      font-size: 2.2rem;
      color: #fff;
    }

    h6 {
      color: #fff;
      font-size: 1.2rem;
      margin-top: 1rem;
      line-height: 2rem;
      padding: 0 3.5rem;
      text-align: left;
      text-indent: 2em;
    }
  }
}

.address {
  padding: 1rem;
  margin-top: 2rem;

  h2 {
    font-size: 1.6rem;
    color: #003368;
    margin-bottom: 2rem;
  }

  .code {
    text-align: center;
    margin-top: 2rem;

    img {
      width: 7.3rem;
      height: 7.3rem;
    }

    p {
      font-size: 1.2rem;
      color: #003368;
      margin-top: 1rem;
    }
  }

  .ad {
    display: flex;
    margin-top: 1rem;

    img {
      width: 1.6rem;
      height: 1.6rem;
    }

    p {
      font-size: 1.2rem;
      color: #003368;
      line-height: 2rem;
      margin-left: 1rem;
    }
  }
}


// 中心内容
.center-box {
  padding: 0rem 1rem;
}

.founder {
  padding-bottom: 2rem;

  h6 {
    color: #115480;
    font-size: 1.2rem;
    text-align: center;
    padding-top: 3rem;
  }

  h3 {
    font-size: 1.8rem;
    color: #115480;
    text-align: center;
    padding-top: 1rem;
  }

  p {
    color: #486995;
    font-size: 1.2rem;
    line-height: 2.4rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    height: 24rem;
    padding-top: 2rem;
  }

  .qm {
    height: 4rem;
    display: flex;
    justify-content: right;
    align-items: center;

    // margin-bottom: 2rem;
    span {
      font-size: 1.2rem;
      color: #486995;
    }

    img {
      width: 3rem;
      height: 1.6rem;
      padding-top: 0;
    }
  }

  .company {
    width: 28rem;
    height: 32rem;
    margin: 2rem auto;
    display: block;
  }

  .ry {
    display: flex;
    justify-content: space-between;

    img {
      width: 8rem;
      height: 5.5rem;
    }
  }

  .ry1 {
    display: flex;
    justify-content: space-between;

    img {
      width: 8rem;
      height: 12rem;
    }
  }


}

.us {
  width: 100%;
  height: 28rem;
  background: url(../assets/aboutUs/bzMap.png) no-repeat;
  background-size: 100% 100%
}
</style>
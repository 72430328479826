<template>
    <div class="box">
        <div class="topBanner">
            <div class="head-card">
                <h3>挖掘奇迹 重塑生命</h3>
                <h6>探索干细胞应用的无限可能，改变疾病治疗的未来!</h6>
            </div>
        </div>
        <div v-show="status == 0">
            <div class="storageItem" v-for="(item, index) in dataList" :key="index">
                <h6>{{ item.title_small }}</h6>
                <h5>{{ item.title_long }}</h5>
                <h2>{{ item.content }}</h2>
                <img :src="imgURL + item.img_url" alt="">
                <button class="btn" @click="handleToDetail(item)">了解更多</button>
            </div>
        </div>
        <div class="our" v-show="status == 1">
            <el-page-header style="margin-top: 1rem;" @back="goBack">
            </el-page-header>
            <div class="our-item" v-for="(item, index) in articleData" :key="index">
                <img :src="imgURL + item.img_url" alt="">
                <p>{{ item.title }}</p>
                <span>干细胞应用-{{ item.cn_create_at }}</span>
                <h6>{{ item.content }}</h6>
            </div>
        </div>

        <!-- <div class="storageItem" v-for="(item,index) in dataList" :key="index">
            <h6>组织工程（Tissue Engineering）</h6>
            <h5>重建结构 组织工程创新</h5>
            <h2>干细胞在组织工程中可用于构建人工组织和器官.通过将干细胞与支架材料结合,可以培养出功能性的组织,如皮肤、骨骼、肌肉等,为替代性治疗提供新的选择.</h2>
            <img src="../assets/application/app1.png" alt="">
            <button class="btn">了解更多</button>
        </div> -->
        <!-- <div class="storageItem">
            <h6>再生医学（Regenerative Medicine）</h6>
            <h5>重塑生命 再生医学应用</h5>
            <h2>干细胞在再生医学中具有巨大潜力,可以修复和替代受损组织和器官.干细胞治疗可以应用于各种疾病和损伤,如心脏病、神经退行性疾病、骨折等,为患者带来新的治疗机会和希望.</h2>
            <img src="../assets/application/app2.png" alt="">
            <button class="btn">了解更多</button>
        </div> -->
        <!-- <div class="sort">
            <div class="item active">全部</div>
            <div class="item">再生医学</div>
            <div class="item">组织工程</div>
            <div class="item">免疫治疗</div>
            <div class="item">药物筛选与开发</div>
            <div class="item">研究与基础科学</div>
        </div> -->
        <!-- <div class="our">
            <div class="our-item">
                <img src="../assets/application/item_img.jpg" alt="">
                <p>干细胞疗法:从实验室到临床</p>
                <span>干细胞应用-2023年8月20日</span>
                <h6>干细胞疗法是再生医学中的一个重要分支，它利用干细胞的多能性和自我更新能力，以修复受损组织和器官。早在数十年前，研究人员就开始探索干细胞在治疗疾病方面的潜力。近期，科学家们通过对诱导多能干细胞(iPSCs)的研究，成功地将患者的体细胞转化为干细胞，从而为个体化治疗奠定了基础。
                </h6>
            </div>
            <div class="our-item">
                <img src="../assets/application/item233.png" alt="">
                <p>个性化的组织工程治疗方案</p>
                <span>干细胞应用-2023年6月20日</span>
                <h6>干细胞在组织工程中可用于构建人工组织和器官.通过将干细胞与支架材料结合,可以培养出功能性的组织,如皮肤、骨骼、肌肉等,为替代性治疗提供新的选择。
                </h6>
            </div>
        </div> -->
    </div>
</template>

<script>
import { GetArticleCategoryList, GetArticleList } from "@/api/index";
export default {
    data() {
        return {
            status: 0,
            dataList: [],
            articleData: [],
            imgURL: 'https://www-api.beizhengc.com'
        }
    },
    created() {
        this.getData();
    },
    methods: {
        goBack() {
            this.status = 0;
        },
        getData() {
            var params = {
                page: 1,
                per_page: 100,
            };
            GetArticleCategoryList(params).then((res) => {
                this.dataList = res.data.data.data;
            });
        },
        handleToDetail(item) {
            this.status = 1;
            let params = {
                category_id: item.category_id,
                page: 1,
                per_page: 100,
            };
            GetArticleList(params).then((res) => {
                // console.log(res);
                this.articleData = res.data.data.data;
            });
        }
    }
}
</script>

<style lang="less" scoped>
.topBanner {
    width: 100%;
    height: 28rem;
    background-image: url("../assets/application/genes.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    .head-card {
        width: 100%;
        height: 11rem;
        margin-top: 8rem;
        text-align: center;
        background-image: url(../assets/storage/banner-bg.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;

        h3 {
            padding-top: 1.7rem;
            font-size: 2.2rem;
            color: #fff;
        }

        h6 {
            color: #fff;
            font-size: 1.2rem;
            margin-top: 1rem;
            line-height: 2rem;
            padding: 0 3.5rem;
            text-align: left;
            text-indent: 2em;
        }
    }
}

.btn {
    width: 18rem;
    height: 4.5rem;
    line-height: 4.5rem;
    font-size: 1.4rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #fff;
    outline: none;
    border: none;
    background: #23989f;
    border-radius: 1.55rem;
    margin: 0 auto;
    margin-top: 2.8rem;
    text-align: center;
    letter-spacing: 0.2rem;
}

.storageItem {
    // width: 100%;
    padding: 1rem;
    margin: 1rem 1rem;
    padding-bottom: 4rem;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 6px;

    h2 {
        color: #005a7e;
        font-size: 1.2rem;
        text-align: left;
        padding-top: 1.2rem;
        padding-bottom: 2rem;

        line-height: 2rem;
        text-indent: 2em;
    }

    h5 {
        color: #115480;
        font-size: 1.8rem;
        padding-top: 1rem;
        text-align: center;
    }

    h6 {
        color: #115480;
        font-size: 1.2rem;
        text-align: center;
        padding-top: 4rem;
    }

    img {
        height: 26rem;
        // width: calc(100% - 2rem);
        width: 100%;
        display: inline-block;
        border-radius: 0.6rem;
    }
}

.sort {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;

    .item {
        font-size: 1.2rem;
        padding: 1rem 0.6rem;
        color: #757575;
        border-radius: 1rem;
    }

    .active {
        background-color: #23989f;
        color: #fff;
        padding: 1rem 1.5rem;
    }
}

.our {
    padding: 1rem;
    background-color: #fff;
    margin-bottom: 2rem;

    .our-item {
        width: 100%;
        margin-top: 3rem;

        img {
            width: 100%;
            height: 24rem;
        }

        p {
            font-size: 1.6rem;
            color: #0d5078;
            font-weight: bold;
            margin-top: 2rem;
            margin-bottom: 1rem;
        }

        span {
            font-size: 1.2rem;
            color: #848484;
        }
    }


    h6 {
        color: #115480;
        font-size: 1.2rem;
        padding-top: 1.2rem;
        line-height: 2.4rem;
    }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_vm._m(0),_c('div',{staticClass:"center-box"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('div',{staticClass:"founder"},[_c('h6',[_vm._v("GMP LABORATORY")]),_c('h3',[_vm._v("GMP实验室")]),_c('el-carousel',{attrs:{"interval":3000,"type":"card","height":"13rem"}},_vm._l((_vm.imgList),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{staticClass:"gmp_img",attrs:{"src":_vm.imgURL + item.img_url,"alt":""}})])}),1)],1),_vm._m(14),_vm._m(15),_vm._m(16)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topBanner"},[_c('div',{staticClass:"head-card"},[_c('h3',[_vm._v("汇聚力量 共铸辉煌")]),_c('h6',[_vm._v("与国内外优秀伙伴合作，共享干细胞研究的丰硕成果，开创行业的新纪元!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"founder"},[_c('h6',[_vm._v("FOUNDER INTRODUCTION")]),_c('h3',[_vm._v("京赣产业联动")]),_c('p',[_vm._v("依托北京公司优质资源，支持革命苏区生物产业。北正旗下江西人体组织样本库是江西省第一家专业干细胞制备中心及人体组织样本存储库。")]),_c('p',[_vm._v("公司位于江西省赣州市经济技术开发区国际企业中心。以“关注生命提高生命质量”为宗旨。坚持“诚信、专业、先进、发展”的企业文化。掌握美国、中国多项专利技术，拥有20 项国家保护专利。")]),_c('p',[_vm._v(" 江西人体组织样本库以“组织细胞库+区域细胞制备中心+细胞质量云平台”(1库 +1 中心+1平台)产业科研发展新模式。推动江西省人体组织样本资源的收集及应用，为革命苏区生物产业发展和人民的生命健康做出应有贡献。 ")]),_c('p',[_vm._v("北正以江西公司作为未来上市主体，江西公司依托北京总部相关资源，在当地建立细胞全产业链的高标准平台。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('img',{attrs:{"src":require("../assets/resource/yyx.png"),"alt":""}}),_c('div',{staticClass:"presend"},[_c('div',[_c('h3',[_vm._v("尹玉新")]),_c('div',{staticClass:"lable"},[_c('i',{staticClass:"el-icon-right iconRight"}),_c('span',[_vm._v("首席科学家")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('img',{attrs:{"src":require("../assets/resource/xyz.png"),"alt":""}}),_c('div',{staticClass:"presend"},[_c('div',[_c('h3',[_vm._v("奚永志")]),_c('div',{staticClass:"lable"},[_c('i',{staticClass:"el-icon-right iconRight"}),_c('span',[_vm._v("临床医学的博士")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('img',{attrs:{"src":require("../assets/resource/gsp.png"),"alt":""}}),_c('div',{staticClass:"presend"},[_c('div',[_c('h3',[_vm._v("高舒平")]),_c('div',{staticClass:"lable"},[_c('i',{staticClass:"el-icon-right iconRight"}),_c('span',[_vm._v("高级顾问")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('img',{attrs:{"src":require("../assets/resource/mjp.jpg"),"alt":""}}),_c('div',{staticClass:"presend"},[_c('div',[_c('h3',[_vm._v("毛建平")]),_c('div',{staticClass:"lable"},[_c('i',{staticClass:"el-icon-right iconRight"}),_c('span',[_vm._v("研发负责人")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('img',{attrs:{"src":require("../assets/resource/wcs.jpg"),"alt":""}}),_c('div',{staticClass:"presend"},[_c('div',[_c('h3',[_vm._v("王长生")]),_c('div',{staticClass:"lable"},[_c('i',{staticClass:"el-icon-right iconRight"}),_c('span',[_vm._v("北京首席专家")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[_c('img',{attrs:{"src":require("../assets/resource/xhb.jpg"),"alt":""}}),_c('div',{staticClass:"presend"},[_c('div',[_c('h3',[_vm._v("辛洪波")]),_c('div',{staticClass:"lable"},[_c('i',{staticClass:"el-icon-right iconRight"}),_c('span',[_vm._v("南昌大学医学院院长")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nteam"},[_c('img',{attrs:{"src":require("../assets/resource/dr.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("200+")]),_c('span',[_vm._v("博士团队")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nteam"},[_c('img',{attrs:{"src":require("../assets/resource/postgraduate.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("500+")]),_c('span',[_vm._v("研究生")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nteam"},[_c('img',{attrs:{"src":require("../assets/resource/researchers.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("500+")]),_c('span',[_vm._v("研究专家")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nteam"},[_c('img',{attrs:{"src":require("../assets/resource/Clinical.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("500+")]),_c('span',[_vm._v("临床专家")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"founder",staticStyle:{"margin-top":"6rem"}},[_c('h6',[_vm._v("QUALIFICATION")]),_c('h3',[_vm._v("荣誉资质")]),_c('div',{staticClass:"ry"},[_c('img',{attrs:{"src":require("../assets/aboutUs/group2.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/aboutUs/group1.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/aboutUs/group3.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/aboutUs/group4.jpg"),"alt":""}})]),_c('div',{staticClass:"ry1"},[_c('img',{attrs:{"src":require("../assets/aboutUs/group1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/aboutUs/group2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/aboutUs/group3.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/aboutUs/group5.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"founder"},[_c('h6',[_vm._v("GROUP QUALIFICATION")]),_c('h3',[_vm._v("专利证书")]),_c('div',{staticClass:"zl"},[_c('img',{attrs:{"src":require("../assets/resource/zl (1).jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/resource/zl (2).jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/resource/zl (3).jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/resource/zl (4).jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/resource/zl (5).jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service"},[_c('img',{attrs:{"src":require("../assets/resource/partner2.png"),"alt":""}}),_c('div',{staticClass:"sve"},[_c('img',{attrs:{"src":require("../assets/resource/hospital.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("Partner")]),_c('span',[_vm._v("北京天坛医院")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service"},[_c('img',{attrs:{"src":require("../assets/resource/partner3.png"),"alt":""}}),_c('div',{staticClass:"sve"},[_c('img',{attrs:{"src":require("../assets/resource/hospital.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("Partner")]),_c('span',[_vm._v("北京地坛医院")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service"},[_c('img',{attrs:{"src":require("../assets/resource/partner1.png"),"alt":""}}),_c('div',{staticClass:"sve"},[_c('img',{attrs:{"src":require("../assets/resource/hospital.png"),"alt":""}}),_c('div',[_c('h2',[_vm._v("Partner")]),_c('span',[_vm._v("中日友好医院")])])])])
}]

export { render, staticRenderFns }
import axios from 'axios'
var baseUrl = 'https://www-api.beizhengc.com'; //请求地址

export default {
    fetchGet(url, params = {}) { //封装get请求
        return new Promise((resolve, reject) => {
            url = baseUrl + url
            axios.get(url, params).then(res => {
                resolve(res)
            }).catch(error => {
                console.log(error.data)
            })
        })
    },
    fetchPost(url, params = {}) { //封装post请求
        return new Promise((resolve, reject) => {
            url = baseUrl + url
            axios.post(url, params).then(res => {
                resolve(res)
            }).catch(error => {
                console.log(error)
            })
        })
    }

}
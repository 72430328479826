<template>
  <div id="app">
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <router-view />
  </div>
</template>
<script>
export default {
  mounted() {
    window.addEventListener(
      'orientationchange' in window ? 'orientationchange' : 'resize',
      (function () {
        //判断是屏幕旋转还是resize
        function c() {
          var d = document.documentElement //获取html元素
          var cw = d.clientWidth || 750
          d.style.fontSize = 10 * (cw / 375) > 40 ? 40 + 'px' : 10 * (cw / 375) + 'px'
        }
        c()
        return c
      })(),
      false
    )
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) == false) {
      window.location.href = 'https://www.beizhengc.com'
    }
  },
}
</script>
<style lang="less">
body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}
</style>

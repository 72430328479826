import Vue from 'vue';
import VueRouter from 'vue-router';
import homeLayout from '../layout/homeLayout.vue';
import home from '../views/home';
import storage from '../views/storage';
import aboutUs from '../views/aboutUs';
import application from '../views/application';
import resource from '../views/resource';
import health from '../views/health';
import finance from '../views/finance';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: homeLayout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: home,
        meta: {
          title: '首页',
        },
      },
      {
        path: 'storage',
        name: 'storage',
        component: storage,
        meta: {
          title: '干细胞存储',
        },
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        component: aboutUs,
        meta: {
          title: '关于我们',
        },
      },
      {
        path: 'application',
        name: 'application',
        component: application,
        meta: {
          title: '细胞应用',
        },
      },
      {
        path: 'resource',
        name: 'resource',
        component: resource,
        meta: {
          title: '北正资源',
        },
      },
      {
        path: 'health',
        name: 'health',
        component: health,
        meta: {
          title: '健康管理',
        },
      },
      {
        path: 'finance',
        name: 'finance',
        component: finance,
        meta: {
          title: '财富投资',
        },
      },
    ],
  },
];
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
